import React from "react";
import styled from "styled-components";

import { BlocksContent } from "@components/shared/sub";
import { Container } from "@util/standard";

interface Props {
  blocks: any;
}

const ContentContainer = styled(Container)`
  word-break:break-all;
  width: 70%;
  margin: 5% auto;
`;

function PrivacyPolicyContent(props: Props) {

  return (
    <ContentContainer>
      <BlocksContent blocks={props.blocks} />
    </ContentContainer>

  );
}

export default PrivacyPolicyContent;
