import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "@components/shared/hero/hero";
import PrivacyPolicyContent from "@components/privacyPolicy/privacyPolicyContent";
import SEO from "@components/shared/seo";

function PrivacyPolicy() {
  const { sanityPrivacyPolicyLanding } = useStaticQuery(graphql`
    {
      sanityPrivacyPolicyLanding {
        privacyPolicyHero {
          ctaScrollButtonText
          heroHeader
          heroBackgroundImage {
            asset {
              fluid {
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
          heroSubText {
            _rawContent
          }
        }
        privacyPolicyContent {
          _rawContent
        }
        seoBlock {
          pageTitle
          slug {
            current
          }
          ogImage {
            asset {
              fluid {
                srcWebp
                srcSet
                srcSetWebp
                src
              }
            }
          }
          pageKeyWords
          pageDescription
        }
      }
    }
  `);

  if (sanityPrivacyPolicyLanding == null) {
    return null;
  }
  const { privacyPolicyHero, privacyPolicyContent } =
    sanityPrivacyPolicyLanding;
  if (privacyPolicyHero == null) {
    return null;
  }
  return (
    <div>
      {/* <SEO
        seoData={seoBlock}
        slug={seoBlock.slug.current ?? "/privacy-policy"}
      /> */}
      <Hero heroContent={privacyPolicyHero} headerIsVisible linkToHomePage />
      <PrivacyPolicyContent blocks={privacyPolicyContent._rawContent} />
    </div>
  );
}

export default PrivacyPolicy;
